<template>
  <div class="field-flex" :style="grow ? 'flex-grow: 1' : ''">
    <div v-if="label" class="d-flex w-100 flex-row justify-content-between">
      <label class="menu-sm text-color-subtle" :class="{ strongtitle: strongtitle }">{{ label }}</label>
      <span v-if="showCharacterLimit" class="small text-color-subtle"> {{ characterCount }}/{{ characterLimit }} </span>
    </div>
    <input @blur="onBlur" v-mask="maskVal" class="custom-input" v-model="internalValue" :type="inputType"
      :placeholder="placeholder" :maxlength="characterLimit" :ref="customRef" :required="isRequired"
      :readonly="readonly" v-bind="$attrs" />
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    placeholder: String,
    characterLimit: Number,
    showCharacterLimit: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    customRef: String,
    inputType: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: false
    },
    maskVal: {
      type: String,
      default: ''
    },
    grow: Boolean,
    strongtitle: Boolean
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  methods: {
    onBlur (event) {
      const inputValue = event.target.value
      this.$emit('blur', inputValue)
    }
  },
  watch: {
    value (newValue) {
      this.internalValue = newValue
    },
    internalValue (newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    characterCount () {
      return this.internalValue.length
    },
    isRequired () {
      return this.required
    },
    isReadonlyVal () {
      return this.readonly
    }
  }
}
</script>

<style scoped lang="scss">
.field-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.custom-input {
  height: 40px !important;
  padding: 0 12px !important;
  width: 100%;
  border-radius: var(--border-radius-small, 4px) !important;
  border: 1px solid var(--stroke-heavy-contrast, #cbd5e1) !important;
  background: var(--surface-primary, #fff);
  box-shadow: none !important;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-primary);
  &:focus {
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.09) !important;
    outline: none !important;
  }
}

::placeholder {
  opacity: 1 !important;
  color: var(--text-detail) !important;
  font-weight: 400;
  font-size: 14px;
}

.strongtitle{
  font-size: 14px !important;
  margin: 0;
  font-weight: 700 !important;
  color: var(--text-color-primary) !important;
}
</style>
